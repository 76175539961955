#report {
  .processing {
    width: 18%;
    background-color: rgb(120, 143, 156);
  }
  .recon {
    width: 18%;
    background-color: rgb(98, 176, 100);
  }
  .bank {
    width: 18%;
    background-color: rgb(109, 93, 168);
  }
  .address {
    width: 18%;
    background-color: rgb(164, 171, 191);
  }
  .nomination {
    width: 18%;
    background-color: rgb(241, 162, 61);
  }
  .nav-pills>li {
    margin: 5px;
    border-radius: 8px;
  }
  .nav-pills>li>a {
    border-radius: 8px;
    text-decoration: none;
    font-size: 20px;
  }
  .nav-pills>li>a {
    color: #fff !important;
  }
  .nav-pills>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  .nav-pills>li.active>a,
  .nav-pills>li.active>a:focus,
  .nav-pills>li.active>a:hover {
    color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px;
  }
}

#rta_tabs {
  .btn {
    border-radius: 50%;
  }
  .btn-info {
    background-color: transparent;
    border: 0px solid #0a1612;
    color: white
  }
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    font-size: 0.8rem;
  }
  .dropdown-item {
    border-radius: 30px;
    color: #0a1612;
    font-weight: bold;
    margin-bottom: 1px;
    border: 1px solid #0a1612;
    background-color: white
  }
}

#recon {}
