.al_navbar {
  .nav_background {
    //  background-color: var(--color-14) !important;
    background: #14213d !important;
    height: 56px;
  }

  .nav_brand {
    border-right: 1px solid white;
    padding-right: 20px;
  }

  // .bg-dark {
  //   background-color: #d8d888 !important;
  //   box-shadow: 0 1px 2px 0 rgba(148, 150, 159, .3); // border-bottom: 1px solid #ccc;
  // }

  .nav_logo {
    width: 40px;
    height: auto;
    max-height: 42px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .nav_logo_text {
    //background: #2a2b2c !important;
    display: inline-block;
    font-weight: 800;
    font-size: 22px;
    font-family: 'Brush Script MT, Brush Script Std, cursive';
    // color: #178AC0;
    // color: var(--color-18) !important;
  }

  .al_login_text {
    color: #0a1612;
    cursor: pointer;
  }

  .nav_item {
    padding-right: 1rem;
    padding-left: 1rem;
    // font-size: 18px;
    font-size: 16px;
    font-weight: lighter;
    // padding-right: 0.5rem;
    // padding-left: 0.5rem;
    // font-size: 14px;
    // font-weight: lighter;
  }

  .nav_item a {
    text-decoration: none;
    //color: var(--color-17) ;
    //color: #f8f9fa !important;
    color: #f8fafa;
  }

  .active {
    // color: white !important;
    color: var(--color-18) !important;
    // color:#9ACD32 !important;
    font-size: 1rem;
    // font-size: 0.875rem;
    font-weight: 500;
    border-top: 2px solid var(--color-18) !important;
    border-bottom: 2px solid var(--color-18) !important;
    //  border-top: 2px solid #9ACD32 !important;
    // border-bottom: 2px solid #9ACD32 !important;
    // color: black !important;
    // font-size: 0.875rem;
    // font-weight: 500;
    // border-top: 1px solid grey;
    // border-bottom: 1px solid grey;
  }

  .nav_dropdown_item {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 13px;
    font-weight: normal;
    border: 2px solid white;
    // border: 1px solid black;
    border-radius: 20px;

    &:focus {
      text-decoration: none;
    }

    .dropdown-toggle {
      text-decoration: none;
      color: white;
      // color: #373737;
    }

    .dropdown-menu {
      border: 0px solid rgba(255, 255, 255, 0.858);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      padding: 0px;
      right: 7px !important;
      left: inherit;

      .dropdown-item {
        color: #373737;
        border-bottom: 1px solid #eeeeee;
        padding: 8px;
        font-size: 13px;
        font-weight: normal;
        cursor: pointer;
      }

      .dropdown-item:hover {
        // background: #14213d ;
        background: #fb9c46 !important;
        color: #fff;
      }

      .border_bottom {
        border-bottom: 1px solid;
        color: #d3d3d3e8;
      }
    }
  }
}

.al_navLink {
  color: #ffc107 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #ffc107 !important;
  border: 1px solid transparent;
  font-weight: 400 !important;
  padding: 0.25rem 0.5rem !important;
  font-size: .875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;


}

.al_navLink:hover {
  text-decoration: none;
  background-color: #ffc107 !important;
  color: #000 !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
