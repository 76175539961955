 .module_rule_modal {
   .modal-lg {
     @media (min-width: 992px) {
       max-width: 100%;

     }
   }

   .modal-dialog {
     @media (min-width: 768px) {
       width: 100%;
       height: 100%;
       margin: 0;
       padding: 0;
     }

   }

   .modal-content {
     @media (min-width: 768px) {
       height: auto;
       min-height: 100%;
       border-radius: 0
     }

   }

   .modal-header {
     @media (min-width: 768px) {
       height: auto;
       min-height: 100%;
       border-radius: 0
     }

   }
 }
