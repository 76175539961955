.form_control_1 {
    border-radius:3.125rem;
    };
    
    .input_bg_error{
        background: #FCF3F2 !important;
        border: none;
    }
    .input_bg_success{
        background:#EFF1F9 !important;
        border: none;
    }
//------------2nd css--------------------
// body{
//     font-family: sans-serif;
//   }
  
//   .label-float{
//     position: relative;
//     padding-top: 13px;
//   }
  
//   .label-float input{
//     border: 1px solid lightgrey;
//     border-radius: 5px;
//     outline: none;
//     min-width: 100% !important;
//     padding: 15px 20px;
//     // font-size: 16px;
//     transition: all .1s linear;
//   }
  
//   // .label-float input:focus{
//   //   border: 2px solid #5BB5A2;
//   // }
  
//   .label-float input::placeholder{
//     color:transparent;
//   }
  
//   .label-float label{
//     pointer-events: none;
//     position: absolute;
//     top: calc(50% - 8px);
//     left: 15px;
//     transition: all .1s linear;
//     -webkit-transition: all .1s linear;
//     -moz-transition: all .1s linear;
//     // background-color: ;
//     padding: 5px;
//     box-sizing: border-box;
//     color: gray;
//   }
//   .label-float input:focus + label,
//   .label-float input:not(:placeholder-shown) + label{
//      font-size: 11px;
//      color: #2BB575;
//     top: 8px;
//     // font-weight: bold;
//     // font-size: 12px;
//     // font-weight: 200;
//     padding-top: 6px;
//   };
