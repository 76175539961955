html {
  font-size: 16px; // rem will calculate from here
}

//global root css
:root {
  --color-1: #51998D;
  --color-2: #70BEA3;
  --color-3: #F4F2D8;
  --color-4: #EEC38E;
  --color-5: #F5B9AD;
  --color-6: #1F2027;
  --color-7: #ffffff;
  --color-8: #1a191e;
  --color-9: #5bb5a2;
  --color-10: #6364ff;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 0.87rem; // 14px
  line-height: 1.5;
}

.app_layout {
  margin-top: 55px !important;
}

.brand_responsive_img {
  width: 100%;
  // height: 3.5vw;
  height: 100%;
  object-fit: contain
}

//no underline
.text_decoration_none {
  text-decoration: none !important;
}

//text-color 
.text_clr_1 {
  color: var(--color-1) !important;
}

.text_clr_2 {
  color: var(--color-2) !important;
}

.text_clr_3 {
  // color: var(--color-3) !important;
  color: rgba(238, 195, 142, 1) !important
}

.text_clr_4 {
  color: var(--color-4) !important;
}

.text_clr_5 {
  color: var(--color-5) !important;
}

.text_clr_6 {
  color: var(--color-6) !important;
}

.text_clr_7 {
  color: var(--color-7) !important;
}

.text_clr_8 {
  color: var(--color-8) !important;
}

.text_clr_9 {
  color: var(--color-9) !important;
}

.text_clr_10 {
  color: var(--color-10) !important;
}

//background-color
// .bg_1 {
//   background-color: var(--color-1) !important;
// }

.bg_2 {
  background-color: var(--color-2) !important;
}

.bg_3 {
  background-color: var(--color-3) !important;
}

.bg_4 {
  background-color: var(--color-4) !important;
}

.bg_5 {
  // background-color: var(--color-5) !important;
  background-color: #f4f2d8 !important;
}

.bg_6 {
  background-color: var(--color-6) !important;
}

.bg_7 {
  background-color: var(--color-7) !important;
}

.bg_8 {
  background-color: rgba(244, 242, 216, 1) !important;
  // background-color: var(--color-8) !important;
}

.bg_clr_8 {
  background-color: rgba(238, 195, 142, 1) !important;
}

.bg_9 {
  background-color: var(--color-9) !important;
}

.bg_clr_9 {
  background-color: rgba(232, 242, 238, 1) !important;
}

//scale down and scale up based on view
.img_responsive_1 {
  width: 100% !important;
  height: auto !important;
}

#navbar.navbar {
  justify-content: center !important;
}

.progress_bar_clr {
  background-image: linear-gradient(0deg, rgba(255, 245, 157, 0.25), rgba(255, 245, 157, 0.25)), linear-gradient(90deg, #5BB5A2 0.93%, #AFD3CB 26.91%);
}
  /*=======================
          New css
  =========================*/
//text size
.banner_1{
  border: 2px solid #2BB5752e;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  text-align: center
};
.text_size_1{
  font-weight: 500;
  font-size: 36px;
  line-height: 43.56px;
  };

  .text_size_2{
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0.15px;
    color: #828282;
  };
  .text_size_3{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #828282;
    text-align: center;
  };
  .text_size_4{
    font-weight: 500;
    font-size: 16px;
    line-height: 19.2px;
    //letter-spacing: -3px;
    color: #1a191e87;
  }
  //color
  .text_clr_16{
    color: #F2451C!important;
  }
  .text_clr_17{
    color: #2BB575 !important;
    //font-weight: 400 !important;
  };
  .text_clr_18{
    color: #00B398 !important
  }
  //bg-color
  .bg_card_clr_2{
    //box-shadow: 5px 0px 70px 5px #81dce333;
    transform: scale(1);
    z-index: 2;
    background-color: #5bb5a212;
    box-shadow: 0px 0px 24px 0px #2308711F;
  };
  .bg_card_1{
    background:#E7FAF1
  };
  .bg_clr_10{
    background:#2BB575 !important;
  };
  .input_field_bg{
  background:#EFF1F9 60% ;
  };
  .ui_bg_clr{
    background-color:#F4F5FA;
  };
  .input_bg_success{
    background:#EFF1F9 !important;
    border: none;
  };

//padding
  .pt_120{
    padding-top: 120px !important;
  };
  .pb_150{
    padding-bottom: 150px !important;
  };
  .text_sz_1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 38.73px;
  }
  .text_sz_2 {
    font-weight: 400;
    font-size: 10.81px;
    line-height: 11.28px;
    color: #000000;
  }
  .text_sz_3 {
    font-weight: 400;
    font-size: 10.06px;
    line-height: 10.96px;
    color: #00000066;
  }
  .text_sz_4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #303972;
  }
  .text_sz_5 {
    font-weight: 400;
    font-size: 9.06px;
    line-height: 10.96px;
    color: #000000;
  }
  .text_sz_6 {
    font-weight: 500;
    font-size: 28px;
    line-height: 33.89px;
    text-align: center;
    color: #230871;
  };
  
  .text_sz_7{
    font-weight: 700;
    font-size: 26px;
    line-height: 43.57px;
    color: #5BB5A2;
  };
  
  .text_sz_8{
    font-weight: 500;
    //font-size: 20px;
    //line-height: 24.57px;
    font-size: 15px;
    color: #5BB5A2;
  };
  .text_sz_9 {
    font-weight: 500;
    // font-size: 20px;
    font-size: 15px;
    line-height: 24.2px;
   color:#1C1C1C
  };
  .text_sz_10 {
    font-weight: 500;
    // font-size:32px;
    font-size: 15px;
    //line-height:38.73px;
   color:#000000
  };
  .text_sz_11{
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color:#0C1E5B;
  }
  //-------------------------------------
  // //tabs
  .custom_tab_2 {
    .nav-item.nav-link {
      color: #0c1e5b;
      font: 14px "Rubik", sans-serif;
      border: 0 !important;
      cursor: pointer;
      font-size: 15px;
    }
  
    .nav-item.nav-link.active {
      color: #2bb575 !important;
      font-weight: 600 !important;
      border-bottom: 3px solid #2bb575 !important;
    }
    .nav-tabs {
      border-bottom: 0px !important;
    }
  }
  

  