.navbar {
  padding: .265rem 8.24rem .265rem 8.24rem
}

.nav-link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  // line-height: 170%;
  color: #1A191E;

}

.navbar-brand {
  margin-right: 6.625rem
}

.mr_1 {
  margin-right: 6.625rem
}

.navbar_wrapper {
  padding: 0px 2vw 0px 3vw;
  height: 70px
}

.nav_link {
  font-style: normal;
  font-size: 16px;
  line-height: 170%;
  color: #1A191E;
  text-decoration: none !important;
  padding: 8px 8px 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_link_active {
  background: #F8F8F8;
  border-radius: 6px;
  // text-decoration: none !important;

}

.nav_link_button {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  // padding: 12px 16px;
  // gap: 10px;
  background: #5BB5A2;
  border-radius: 6px;
  // text-decoration: none !important;

}

.vertical_nav_wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  background-color: white;
  width: 100%;
  margin-right: -53px;
  margin-left: -40px;
  padding: 10px
}

.icon_nav {
  padding: 5px 15px 5px 15px;
  border-radius: 100px;
  border: 1px solid #DEDEDE;
}

@media screen and (max-width: 1184px) {
  .nav_link {
    font-size: 10px;
  }

  .brand_responsive_img {
    width: 150px // width: 100%
  }
}

@media screen and (max-width: 420) {}
