// spin loader
.spin_loader {
  display: inline-block;
  height: 50px;
  width: 50px;
  border-width: 3px;
  border-top-width: 3px;
  border-top-color: #36D7B7;
  border: solid rgba(255,255,255,0.35);
  border-radius: 50%;
  border-top: solid;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


// content loader
.content_loader {
  display: inline-block;
  border-radius: 5px;
  height: 60px;
  width: 150px;
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(lightgray 12px, transparent 0),
    linear-gradient(lightgray 12px, transparent 0);
  background-size: 70px 150px, /* highlight */ 80px 120px, 120px 120px;
  animation: shine 1s infinite linear;
  background-repeat: repeat-y;

  background-position: 0 0, /* highlight */ 20px 20px, 0 40px;
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 20px 20px,
      0px 40px;
  }
}
