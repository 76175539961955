// button
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn.active {
  outline: none;
  box-shadow: none;
}

.al_button {
  // background:rgba(112, 190, 163, 1) ;
  //background: #2BB575;
  background:  #2BB575;
  color: #fff;
  font-weight: 500;
}

.al_button:hover,
.al_button:active,
.al_button:visited,
.al_button:focus {
  // background: #28c777 !important;
  //background: #41d48a !important;
  background: #30be7c !important;
  //background: rgba(81, 153, 141, 1) !important;
  //border: 1px solid #28c777 !important;
  border: 10x solid rgba(81, 153, 141, 1) !important;
  color: #fff !important;
}

.al_button.active {
  background: #28c777 !important;
  border: 1px solid #28c777 !important;
}

.al_button_1 {
  color: #aab9ce;
}

.al_button_1.active {
  border-bottom: 3px solid #00c3d9 !important;
  font-weight: 700;
}

//third button style
.al_button_3 {
  background: var(--color-12);
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}

.al_button_3:hover,
.al_button_3:active,
.al_button_3:visited,
.al_button_3:focus {
  border: 2px solid var(--color-12) !important;
  background-color: var(--color-12);
  box-shadow: 0px 15px 20px rgba(232, 115, 149, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

//4th button style
.al_button_4 {
  // background: var(--color-4);
  background: #70bea3 ;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}

.al_button_4:hover,
.al_button_4:active,
.al_button_4:visited,
.al_button_4:focus {
  border: 2px solid #70bea3  !important;
  background-color: #70bea3 ;
  box-shadow: 0px 15px 20px #bfdcd2;
  color: #fff;
  transform: translateY(-7px);
}

.al_button_5 {
  border-bottom: 1px solid var(--color-8);
  color: var(--color-1);
}

.al_button_5:hover,
.al_button_5:active,
.al_button_5:visited,
.al_button_5:focus {
  font-weight: 700;
  border: 2px solid var(--color-8);
  cursor: pointer !important;
}

.al_button_6 {
  border: 1px solid var(--color-9);
  cursor: pointer !important;
  color: var(--color-9);
}

.al_button_7 {
  background: #ffff;
  //color: gray;
  //color: var(--color-4) !important;
  color: #14213d !important;
  font-weight: 500;
  //border: 1px solid gray !important;
  //border: 1px solid green !important;
  border: 1px solid #70bea3 !important;
}

.al_button_7:hover,
.al_button_7:active,
.al_button_7:visited,
.al_button_7:focus {
  background: var(--color-4) !important;
  border: 1px solid #70bea3 !important;
  color: #fff !important;
}

.al_button_8 {
  // background: #ffff;
  background: #70bea3;
  //background: var(--color-4);
  // color:  gray;
  color: var(--color-6);
  font-weight: 500;
  // border: 1px solid gray !important;
}
// .al_button_16 {
//   background: rgb(91, 181, 162) !important;
//   color: #fb9c46 !important;
//   font-weight: 500;
// }

.al_button_8:hover,
.al_button_8:active,
.al_button_8:visited,
.al_button_8:focus {
  // background: gray !important;
  background: var(--color-14);
  border: 1px solid gray !important;
  color: var(--color-18) !important;
}

.al_button_9 {
  border-radius: 20px;
  padding: 0.5rem;
  color: #000;
  background: #fff;
  border: 2px solid rgba(81, 153, 141, 1);
  cursor: pointer;
  transition: color 0.3s;
  background: 0.3s, border-color 0.3s;
}

.al_button_9:hover {
  //background: var(--color-19) !important;
  //background:  rgba(81, 153, 141, 1) !important;
  background: #2BB575  !important;
  color: white !important;
}

.al_button_9:focus {
  outline: unset;
}

.active_button {
  //background: var(--color-19) !important;
  // background: var(--color-4) !important;
  //background:rgba(112, 190, 163, 1) !important;
  background: #2BB575 ;
  color: white;
}

//custom button
.al_button_01 {
  background-color: var(--color-19);
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  border: none;
}

.al_button_green {
  .btn {
    border-color: #5BB5A2 !important;
    background-color: #fff;
    color: #5BB5A2;
    font-weight: 500;
    border-radius: 4px;
    // border: none;
  }

  .active {
    background-color: #5BB5A2;
    color: #fff;

  }
}

.btn_edit {
  // background: var(--color-19) !important;
  background: #fb9c46;
}

.btn_refresh {
  background: gray !important;
}

.btn_delete {
  background: var(--color-5);
}

.btn_success {
  background: var(--color-11);
}

.btn_color_1 {
  background: var(--color-1) !important;
  color: white;
  font-weight: 500;
}

.btn_color_2 {
  background: var(--color-12);
}

.btn_color_3 {
  background: var(--color-8);
}

//circular button
.btn_circular {
  border-radius: 50%;
}

.btn-xs {
  padding: 0.2rem 0.5rem;
  font-size: 0.6rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.btn_event {
  pointer-events: none;
}

.btn_spinner {
  position: absolute;
  left: 30%;
  top: 1px;
  display: inline-block;
  height: 16px;
  width: 16px;
  border-width: 3px;
  border-top-width: 3px;
  border-top-color: #36d7b7;
  border: solid rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  border-top: solid;
  -webkit-animation: btn_spin 1s linear infinite;
  /* Safari */
  animation: btn_spin 1s linear infinite;
}

@keyframes btn_spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.al_button_10 {
  background: white !important;
  border: 1px solid deepskyblue !important;
  color: deepskyblue !important;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.al_button_10:hover,
.al_button_10:visited,
.al_button_10:focus {
  background: deepskyblue !important;
  border: 1px solid deepskyblue !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_10.active {
  background: deepskyblue !important;
  border: 1px solid deepskyblue !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_11 {
  background: white !important;
  border: 1px solid #2BB575 !important;
  color: #2BB575 !important;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.al_button_11:hover,
.al_button_11:visited,
.al_button_11:focus {
  background: #2BB575 !important;
  border: 1px solid #2BB575 !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_11.active {
  background: rgb(98, 189, 169) !important;
  border: 1px solid rgb(98, 189, 169) !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_12 {
  background: white !important;
  //border: 1px solid var(--color-19) !important;
  border: 1px solid #2BB575 !important;
  color: #51998d !important;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.al_button_12:hover,
.al_button_12:visited,
.al_button_12:focus {
  background: #2BB575 !important;
  border: 1px solid #2BB575 !important;
  color: white !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_12.active {
  background: #2BB575 !important;
  border: 1px solid #2BB575 !important;
  color: white !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_13 {
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  color: var(--color-9)
}

.al_button_13:hover {
  color: var(--color-13)
}

.al_button_14 {
  padding: 10px 20px;
  border-radius: 7px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: #2BB575;
  border: 2px;
}

.al_button_14:hover,
.al_button_14:visited,
.al_button_14:focus {
  //background-color:rgba(81, 153, 141, 1);
  background-color: #36bd7e;
  font-weight: 600;
}


.al_button_15 {
  background: white !important;
  border: 1px solid #df3868 !important;
  color: #df3868 !important;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.al_button_15:hover,
.al_button_15:visited,
.al_button_15:focus {
  background: rgba(245, 185, 173, 1) !important;
  border: 1px solid #df3868 !important;
  color: #fff !important;
  // font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_15.active {
  background: rgba(245, 185, 173, 1) !important;
  border: 1px solid #df3868 !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_new {
  background: #5BB5A2 !important;
  color: #FFFFFF !important;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 18px;
};

.al_button_new_1 {
  background: rgba(245, 185, 173, 1) !important;
  color: #FFFFFF !important;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 18px;
};

.al_button_new_2{
  // background: #fb9c46 !important;
  background:#2BB575 !important;
  color: #FFFFFF !important;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
}

.al_button_new_3 {
  background:rgba(238, 195, 142, 1) !important;
  border: 10x solid rgba(238, 195, 142, 1) !important;
  color: #fff !important;
  font-weight: 500
}

.tab_button {
  border-radius: 25px;
  padding: 11px 24px;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: rgba(26, 25, 30, 0.5);
}

.tab_button:hover,
.tab_button:visited,
.tab_button:focus {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: 1px solid rgba(99, 100, 255, 1) !important;
  color: rgba(99, 100, 255, 1);
  // font-weight: bold;
  font-weight: 700
}

.tab_button.active {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: 1px solid rgba(99, 100, 255, 1) !important;
  color: rgba(99, 100, 255, 1);
  font-weight: 700
}


.al_button_new_4 {
  background:var(--color-21) !important;
  color: var(--color-24) !important;
  // border-radius: 12px;
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 400;
}
.al_button_new_5 {
  // border-radius: 12px;
  border-radius: 10px;
  border: 2px solid var(--color-21) !important;
  padding: 12px 24px;
  font-size: 16px;
  --bs-btn-hover-color: var(--color-24) !important;
  --bs-btn-hover-bg: var(--color-21) !important;
}

.al_button_new_7 {
  border-radius: 8px;
  border: 1px solid var(--color-21);
  font-size: 12px;
}
.al_button_new_7:hover,.al_button_new_7.active {
  color: var(--color-24) !important;
  background-color: var(--color-21) !important;
}
.al_button_new_8 {
  border-radius: 7px;
  border: 0.5px solid var(--color-23);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.al_button_new_8:hover,.al_button_new_8.active {
  color: var(--color-24) !important;
  background-color: var(--color-21) !important;
}