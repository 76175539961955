.list-group-item {
  padding-left: 1.9rem;
}

// #fund_list {}
#client_dashboard {
  // .active{

  // }
}
