.new {
  width: 25.33%;
  background-color: white;
  // border: 1px solid #0a1612 !important;
  // border-radius: 40px
}

.rta {
  width: 25.33%;
  background-color: white;
  // border: 1px solid #0a1612 !important;
  // border-radius: 40px
}

.btn_nav_link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  min-height: 100px;
  font-size: 20px;
  background: #efefef;
  color: #1c334a;
  margin-right: 2px;
  width: 98%;
}
.crm {
  width: 25.33%;
  background-color: white;
  // border: 1px solid #0a1612 !important;
  // border-radius: 40px
}

.color-placeholder {
  color: #b5b4b4;
}

.label-for-check {
  display: none;
}

.right {
  float: right;
}

.hidden {
  display: none !important;
}

.modalrow {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 3%;
  font-size: 16px;
}

.modalsubmit {
  height: 60px;
  margin-left: 75%;
}

.select-wrap+label {
  display: block;
  position: absolute;
  top: -13px;
  /* font-size: .8rem; */
  font-size: 11px;
  color: #00bcd4 !important;
}

.select-wrap {
  color: #1c334a !important;
}

.autoComplete-label-hide {
  position: absolute;
  transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -webkit-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  color: #bebebe !important;
  left: 0;
  top: 3px;
}

.autoComplete-label {
  display: block;
  position: absolute;
  top: -13px;
  /* font-size: .8rem; */
  font-size: 11px;
  color: #00bcd4 !important;
}

.test-valid-date {
  display: none;
  position: absolute;
  top: -13px;
  /* font-size: .8rem; */
  font-size: 11px;
  color: #00bcd4;
}

.kycsearch{
  background-color: #fafafa;
  width: 50%;
}

.nojoint {
  width: 35%;
}

.onejoint {
  width: 68%;
}

.twojoint {
  width: 91%;
}

.tabWidth {
  width: 100%;
}

#accountCreation,
#investorModal {
  .error-label{
    border: none !important;
    color: #f50b41;
    font-size: 12px;
  }
  .btn {
    width: auto;
  }
  label {
    font-weight: 400;
    color: #1c334a;
  }
  .form-group {
    margin-bottom: 10px;
    position: relative;
  }
  .form-input {
    padding-top: 21px;
  }
  .form-label {
    position: absolute;
    transition: 0.25s ease;
    -moz-transition: 0.25s ease;
    -webkit-transition: 0.25s ease;
    -o-transition: 0.25s ease;
    -ms-transition: 0.25s ease;
    color: #bebebe;
    left: 0;
    top: 23px;
  }
  .nav-tabs .nav-link {
    min-height: 100px;
    font-size: 20px;
  }
  .nav-item {
    text-align: center;
    width: 16%;
  }
  .nav-tabs .nav-link.active {
    background: #00bcd4;
  }
  .nav-tabs .nav-link {
    background: #efefef;
    color: #1c334a;
    margin-right: 2px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  input {
    transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    padding-top: 10px;
    -webkit-appearance: none;
  }
  input:focus+.form-label,
  input:valid+.form-label {
    top: 1px;
    font-size: 11px;
    color: #00bcd4;
  }
  input,
  select {
    box-sizing: border-box;
    width: 100%;
    display: block;
    border: none;
    /* font-family: Poppins;
    color: #fff; #00bcd4 !important; */
    background: transparent;
    border-bottom: 1px solid #999;
    padding: 4px 0;
  }
  input:focus,
  select:focus {
    border-bottom: 1px solid #00bcd4;
  }
  .error {
    // border-bottom: 1px solid #f50b41 !important;
  }
  input,
  select,
  textarea {
    outline: none;
    appearance: unset !important;
    -moz-appearance: unset !important;
    -webkit-appearance: unset !important;
    -o-appearance: unset !important;
    -ms-appearance: unset !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    margin: 0;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important;
  }
  input[type="checkbox"] {
    appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -webkit-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    -ms-appearance: checkbox !important;
  }
  input[type="radio"] {
    appearance: radio !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    -o-appearance: radio !important;
    -ms-appearance: radio !important;
  }
  .btn-warning {
    color: #212529;
    background-color: #d39e00 !important;
    border-color: #c69500 !important;
    padding: 0.375rem 0.75rem;
  }
  .btn-dark {
    color: #fff;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
  }
  .btn-danger {
    color: #fff;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
}

#rtaDataSelection {
  .modal-width {
    max-width: 1328px;
  }
}

//OnBoardinTabs
#onboardin_tabs {
  .btn {
    border-radius: 50%;
  }
  .btn-info {
    background-color: transparent;
    border: 0px solid #0a1612;
    color: #0a1612
  }
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    font-size: 0.8rem;
  }
  .dropdown-item {
    border-radius: 30px;
    color: #0a1612;
    font-weight: bold;
    margin-bottom: 1px;
    border: 1px solid #0a1612;
    background-color: white
  }
}

#accountCreation {
  .btn_link {
    color: #0a1612;
    height: 50px;
    font-size: 18px
  }
  .active {
    font-size: 16px;
    color: #373737;
    background: white;
    font-weight: bold; // border-top:1px solid lightskyblue;
    // border-bottom:1px solid lightskyblue;
    border-radius: 80px;
    border: 1px solid #373737; // border-radius: 20px
    // border-radius: 20px;
  }

  button:focus {outline:0;}
}
