// #home {
//   position: relative;
//   opacity: 0.8;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url('https://pulse-dreams-view.s3.ap-south-1.amazonaws.com/how_it _works_mobile.jpg');
//   height: 800px;
//   padding-top: 200px;

//   .heading_text {
//     font-size: 2rem;
//     color: #fff;
//   }
// }

.last_updated {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 5px 10px;
  color: #fff;
  background: #444;
  font-size: 0.75rem;
}

.wrap_text_daisplay {
  max-height: 220px;
  overflow: hidden;
}

// mobile...........
#home {
  position: relative;
  background-position: center;
  background-size: cover;
  background-image: url("https://pulse-dreams-view.s3.ap-south-1.amazonaws.com/how_it _works_desktop.jpg");
  height: 484px;
  width: 100%;
  padding-top: 121px;
}

#home {
  background-image: url("https://pulse-dreams-view.s3.ap-south-1.amazonaws.com/how_it _works_mobile.jpg");
}

@media screen and (max-width: 780px) {
  #home {
    height: 817px !important;
    width: 90%;
    background-image: url("https://pulse-dreams-view.s3.ap-south-1.amazonaws.com/how_it _works_mobile.jpg");
  }
}

@media (min-width: 781px) and (max-width: 2400px) {
  #home {
    background-image: url("https://pulse-dreams-view.s3.ap-south-1.amazonaws.com/how_it _works_desktop.jpg");
  }
}

//new scss
.image_dimension {
  width: 800px !important;
  height: 800px !important;
}

.f_w_500 {
  font-weight: 500 !important;
}

// #SectionTwo {
//   #GirlImage {
//     height: auto;
//     width: 100%
//   }
// }

.text_1 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
}

.text_2 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #1a191e;
}

.text_3 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

.text_4 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 8vw;
  // line-height: calc((32 * 1.5) / 100);
  line-height: 130%;

}

.text_5 {
  // font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.text_6 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}

.text_7 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
}

.text_8 {
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
}

.text_9 {

  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.text_10 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  background: linear-gradient(180deg, #6364ff 64.58%, #563acc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text_11 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: rgba(26, 25, 30, 0.5);
}

.text_12 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.03em;
}

.text_13 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: rgba(26, 25, 30, 0.5);
}

.text_14 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  // color: #5bb5a2;
}

.text_15 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
}

.text_16 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #6364ff;
  letter-spacing: -0.03em;
}

.text_17 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.03em;
  // color: #1a191e;
}

.text_18 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  color: #1a191e;
}

.text_19 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.29em;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #5bb5a2;
}

.text_20 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: #343434;
}

.text_21 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #999999;
}

.text_22 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #363940;
}

.text_23 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.01px;
  // color: #6364ff;
}

.text_24 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41.66px;
  color: #4a4a4a;
}

.text_25 {
  // font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}

.text_26 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 179%;
  text-align: center;
  color: #676767;
}

.text_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text_27 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: linear-gradient(180deg, #6364ff 64.58%, #563acc 100%);
  background-clip: text;
  // color: #6364ff;
}

.text_28 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  color: #4a4a4a;
}

.text_29 {
  // font-family: "Cabin";
  // font-style: normal;
  // font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.text_30 {
  // font-family: 'Inter' !important;
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-decoration: none;
}

.text_31 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;
}

.text_32 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
}

.text_33 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  color: #000000;
}

.text_34 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3A3A3A;

}

.text_35 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3a3a;
}

.text_36 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #1a191e;
}

.text_37 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
}

.text_38 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;
}

.text_39 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  // color: #1a191e;
}

.text_40 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

.text_41 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

.text_42 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}

.text_43 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.text_44 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.03em;
}

.text_45 {
  // font-family: "Cabin";
  // font-style: normal;
  font-size: 14px;
  line-height: 180%;
}

.text_46 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.text_47 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}

.text_48 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.03em;
}

.text_49 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: rgba(26, 25, 30, 0.5);
}

.text_50 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #343434;
}

.text_51 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #525252;
}

.text_52 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.text_53 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #ffffff;
}

.text_54 {
  // font-family: "Inter";
  // font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
}

.text_55 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #1a191e;
}

.text_56 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 120%;
  letter-spacing: -0.03em;
  // color: #1a191e;
}

.text_57 {

  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  // color: #1a191e;
}

.text_58 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  // color: #1a191e;
}

.text_59 {
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 43px;
  text-align: center;
  color: #343434;
}

.text_60 {
  // font-family: "Cabin";
  // font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #999999;
}

.text_61 {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 29px;
  text-transform: capitalize;
  color: #808080 !important;
}

.text_62 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  text-transform: capitalize;
  color: #808080;
}

.text_63 {
  // font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  // text-transform: capitalize !important;
  color: #808080 !important;
}

.text_64 {
  // font-family: 'Cabin' !important;
  // font-style: normal;
  font-weight: 400 !important;
  font-size: 1.6rem !important;
  line-height: 44px !important;
  color: #000000 !important;
}

.text_65 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #808080 !important;
}

.text_66 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.text_67 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #595959;
}

.text_68 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #808080;
}

.text_69 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
}

.text_70 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-transform: capitalize;
  color: #808080;
}

.text_71 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  color: #1a191e;
}

.text_72 {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  //line-height: 110%;
  line-height: 103%;
  letter-spacing: -0.03em;
  color: #1a191e;
}

.text_73 {
  // font-family: 'Cabin';
  // font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #737373;
}

.text_74 {
  // font-family: 'DM Sans';
  // font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #1a191e;
}

.text_75 {
  // font-family: 'Inter';
  // font-style: normal;
  // font-weight: 400;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #1a191e;
}

.text_76 {
  // font-family: 'Inter';
  // font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 0px;
  color: #1d1d1d;
}

.legend {
  width: 12px;
  height: 12px;
}

.box_card {
  background: #e8f2ee;
  height: 218px;
}

.calculator_card {
  height: 400px;
  background: #ffffff;
  box-shadow: 2px 11px 30px rgba(51, 51, 51, 0.08);
  border-radius: 15px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.link_wrapper {
  padding: 1.87em 3.88em;
  border-radius: 6px;
}

.link_wrapper_1 {
  padding: 9px 24px;
  border-radius: 6px;
}

.link_wrapper_2 {
  padding: 16px 24px;
  border-radius: 6px;
}

.link_wrapper_3 {
  padding: 16px 109px;
  border-radius: 6px;
}

.row_padding_y {
  padding: 60px 0px 30px 0px;
}

.row_padding {
  padding: 0vw 9.84vw 0vw 9.84vw;
}

.row_padding_1 {
  padding: 9vw;
}

.wrapper_1 {
  margin-top: 21px;
  margin-bottom: 21px;
}

#topButton {
  margin-bottom: 21px;
}

.row_top_buffer {
  margin-top: 32px;
}


// .responsive_iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }
.wrap-element {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.wrapped-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.card_wrapper {
  border: 2px solid lightgrey;
  border-radius: 20px;
  padding: 28px;
}

.card_wrapper_1 {
  width: 100%;
  height: 120px;
  border: 2px solid lightgrey;
  border-radius: 20px;
  padding: 28px;
  background-color: #ffffff !important;
}

.card_wrapper_2 {
  width: 305px;
  height: 120px;
  border: 2px solid lightgrey;
  border-radius: 20px;
  padding: 28px;
  background-color: #ffffff !important;
}

.card_wrapper_3 {
  background: #ffffff;
  box-shadow: 2px 11px 30px rgba(51, 51, 51, 0.08);
  border-radius: 15px;
  // height: 332.701904296875px;
  // width: 306px;
  padding: 12px 22px 12px 22px;
}

.card_wrapper_4 {
  padding: 16px;
  background: #f8f8f8;
  border-radius: 10px;
  // height: 134px;
  // width: 334px;
}

.card_wrapper_5 {
  background: #5bb5a2;
  border-radius: 20px;
  padding: 60px 10px;
  height: auto;
  // height: 134px;
  // width: 334px;
}

.card_wrapper_6 {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px;
}

.image_wrapper {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #e8f2ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_wrapper_1 {
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: #5bb5a2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_wrapper_2 {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #5bb5a2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_wrapper_3 {
  height: 30px;
  width: 70px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5bb5a2;
}

.image_wrapper_4 {
  width: "40px" !important;
  height: "40px" !important;
  background: #e8f2ee;
  border-radius: 100px;
}

.image_wrapper_5 {
  width: 26px;
  height: 26px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_wrapper_6 {
  width: 30.83px;
  height: 30.83px;
  background: rgba(91, 181, 162, 0.25);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button_wrapper {
  background-color: #ffffff;
  border: 2px solid #6364ff;
  border-radius: 25px;
  padding: 11px 24px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
}

.button_wrapper_1 {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 10px 24px;
}

.rating_wrapper {
  width: 43.66px;
  height: 21.86px;
  background-color: #fff8e6;
  border: 0.5px solid #fac525;
  border-radius: 5px;
}

.value_wrapper {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 44px;
  text-transform: capitalize;
  color: #000000;
}

.rating_dimension {
  width: "12.88px";
  height: "11.42px";
}

.image_dimension_1 {
  width: 276px !important;
  height: 365px !important;
}

.image_dimension_2 {
  height: 486px;
  width: 306px;
}

.image_dimension_3 {
  width: "15px" !important;
  height: "15px" !important;
}

.image_dimension_4 {
  width: 1000px !important;
  height: auto !important;
}

.box_dimension {
  height: 442px;
  width: 316px;
}

.card_dimension {
  width: 276px !important;
}

.al_badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26px;
  height: 26px;
  background: #e8f2ee;
  border-radius: 100px;
}

.al_badge_1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #e8f2ee;
  border-radius: 100px;
}

.al_badge_2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #e8f2ee;
  border-radius: 100px;
}

.testinomial_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 15px;
  gap: 15px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.hide_content {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.flex_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.flex_container_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 10px;
  background: #f8f8f8;
  border-radius: 4px;
}

.text_wrapper_active {
  padding: 12px 18px;
  background: #F9F9F9;
  border: 1px solid rgba(148, 148, 148, 0.5);
  border-radius: 24px;
}

.text_wrapper_disabled {
  padding: 12px 18px;

}

.news_letter_Card {
  height: 381px;
  // width: 306px;
  border-radius: 0px;
  padding: 52px 20px 52px 20px;
  background: #e8f2ee;
}

.al_button_subscribe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 13px;
  background: #5bb5a2;
  border-radius: 4px;
  width: 131px;
  height: 37px;
}

.input_field_width {
  width: 265px;
}

//custom input
.input_container {
  border-bottom: 1px solid #000000;
  line-height: 44px;
}

input.custom_input_field {
  border: 0px;
  line-height: 1px;
  font-style: normal;
  font-weight: 700;
  border-bottom: 1px solid #000000;
}

.input_container input[type="text"] {
  font-size: 25px;
}

//custom_tab
.custom_tab_active {
  border-bottom: 3px solid #6364ff !important;
  color: #54d4a0 !important;
}

// .custom_goal_planning_tab {
//   .nav-item.nav-link {
//     font-style: normal;
//     font-weight: 400 !important;
//     font-size: 24px !important;
//     border: 0px
//   }

//   .nav-item.nav-link.active {
//     font-style: normal;
//     color: #54D4A0 !important;
//     font-weight: 700 !important;
//     font-size: 24px !important;
//     border-bottom: 2px solid #6364FF !important;
//   }

// }
//custom radio button
.new_radio_btn_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.new_radio_btn_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #54d4a0;
}

/* On mouse-over, add a grey background color */
.new_radio_btn_container:hover input~.checkmark {
  background-color: #54d4a0;
}

/* When the radio button is checked, add a blue background */
.new_radio_btn_container input:checked~.checkmark {
  background-color: #54d4a0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.new_radio_btn_container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.new_radio_btn_container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}


//slider style
.rc-slider-handle {
  width: 24px ;
  height: 24px ;
  background-color: #54d4a0 !important;
  border: 0px;
  margin-top: -10px;
}

.rc-slider-rail {
  height: 8px;
  background: #f0f0f0;
  border-radius: 39px;
}

.rc-slider-track {
  height: 8px;
  background: var(--color-21);
  border-radius: 39px;
}

.rc_slider_track .rc-slider-track {
  height: 8px;
  background:  var(--color-26);
  border-radius: 39px;
}

.ontrack .rc-slider-track {
  background:  var(--color-21);
}

.goals_slider{
  .rc-slider-handle {
    width: 0px ;
    height: 0px ;
  }
  .rc-slider-disabled {
    background:none;
  }
  
  .rc-slider-rail {
    height: 8px;
    background: #f0f0f0;
    border-radius: 39px;
  }
  
  .rc-slider-track {
     height: 8px;
    //background: #54d4a0;
    border-radius: 39px;
  }
}

.fund_details{
  .rc-slider-handle {
    width: 18px ;
    height: 18px ;
    background-color: #54d4a0 !important;
    border: 0px;
    margin-top: -7px;
  }
  
  .rc-slider-rail {
    // height: 8px;
    background: #f0f0f0;
    border-radius: 39px;
  }
  
  .rc-slider-track {
     height: 4px;
    //background: #54d4a0;
    border-radius: 39px;
  }
}
.rc-slider-tooltip-inner {
  min-width: 35px !important;
  height: 26px !important;
  font-size: 13px !important;
  background-color: #45997c !important;
  border-radius: 6px !important;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #45997c !important;
}

//custom_input
.custom_input {
  all: unset;
  cursor: pointer !important;
}

.pe_auto {
  cursor: pointer !important;
}

.form_card_skin {
  background: #ffffff !important;
  border-radius: 10px !important;
}

@media screen and (min-width: 1207px) {
  // #SectionTwo {
  //   #GirlImage {
  //     min-height: 1207px;
  //     width: 100%
  //   }
  // }

  .text_1 {
    font-size: 3.6vw;
  }

  .text_2 {
    font-size: 1.3vw;
  }

  .text_4 {
    font-size: 3.8vw;
    // font-size: 64px !important;
    line-height: 130% !important;
  }

  .text_7 {
    font-size: 62px;
  }

  .text_8 {
    font-size: 24px;
  }

  .text_26 {
    font-size: 14px;
  }

  .link_wrapper {
    padding: 1.15em 1.7em;
  }

  #topButton {
    margin-right: 42px;
  }

  .row_top_buffer {
    margin-top: 64px;
  }

  .card_wrapper_1 {
    width: 100%;
    height: 135px;
    border: 2px solid lightgrey;
    border-radius: 20px;
    padding: 28px;
  }

  .card_wrapper_3 {
    padding: 12px 28px 61px 28px; // padding: 5%
  }



  // .form_card_structure {
  //   width: 100%;
  //   height: 906px;
  //   padding: 50px 35px;
  // }

  //
  // .form_card {
  //   background: #FFFFFF !important;
  //   border-radius: 10px !important;
  // }

  .text_12 {
    font-size: 1vw;
  }

  .text_13 {
    font-size: 14px;
  }

  .text_15 {
    font-size: 16px;
  }

  .text_16 {
    font-size: 24px;
  }

  .text_17 {
    font-size: 64px;
  }

  .text_18 {
    font-size: 24px;
  }

  .text_19 {
    font-size: 1.29em;
  }

  .text_20 {
    font-size: 36px;

  }

  .text_22 {
    font-size: 1.51em;
  }

  .text_23 {
    font-size: 24px;
    line-height: 29.16px;
  }

  .text_24 {
    font-size: 64px;
    line-height: 83.33px;
  }

  .text_27 {
    font-size: 24px;
    line-height: 29px;
  }

  .text_28 {
    font-size: 3.6vw;
    line-height: 110%;
  }

  .text_29 {
    font-size: 24px;
    line-height: 35px;
  }

  .text_32 {
    line-height: 21px;
  }

  .text_33 {
    font-size: 64px;
  }

  .text_34 {
    font-size: 24px;
    line-height: 36px;
  }

  // .text_36 {
  //   font-size: 1.43em;
  // }

  .text_37 {
    font-size: 64px;
  }

  .text_38 {
    font-size: 18px !important;
  }

  // .text_39 {
  //   font-size: 1.15em;
  // }

  .text_44 {
    font-size: 64px;
    line-height: 110% !important;
  }

  .text_45 {
    // font-weight: 500;
    font-size: 18px;
    line-height: 180%;
  }

  .text_52 {
    font-size: 64px;
  }

  .text_53 {
    font-size: 18px;
  }

  .text_56 {
    font-size: 28px;
  }

  .text_57 {
    font-size: 16px;
  }

  .text_71 {
    font-size: 20px;
  }

  .text_72 {
    // font-size: 80px;
    font-size: 53px;
  }

  .text_74 {
    //font-size: 32px;
    font-size: 28px;
  }

  .text_75 {
    font-size: 20px;
  }

  .text_76 {
    font-size: 24px;
  }

  .value_wrapper {
    font-size: 30px;
  }

  .rating_dimension {
    height: 11.419300079345703px;
    width: 12.877310752868652px;
  }

  .image_dimension_1 {
    width: 254px !important;
    height: 365px !important;
  }

  .image_dimension_2 {
    height: 664px;
    width: 618px;
  }

  .image_dimension_4 {
    width: 100% !important;
    height: auto !important;
  }

  .box_dimension {
    height: 482px;
    width: 401px;
  }

  .card_dimension {
    width: 254px !important;
  }

  .image_wrapper_3 {
    width: 50px;
    height: 50px;
  }

  .image_dimension_3 {
    width: "20px" !important;
    height: "20px" !important;
  }

  .flex_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card_wrapper_5 {
    padding: 96px 76px;
    height: auto;
  }

  .input_field_width {
    width: 342px;
  }

  .box_card {
    // height: 472px;
    height: 14rem;
  }

  .text_clamp {
    display: box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: none;
    overflow: none;
  }

  .legend {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 1207px)and (min-width: 991px) {
  .text_4 {

    font-weight: 400;
    font-size: 4vw;
    // line-height: calc((32 * 1.5) / 100);
    line-height: 130%;


  }
}

.legend_1 {
  width: 15px;
  height: 15px;
};

/* Media Query for Large screens */
@media (min-width: 621px) {
  .card_position_right {
    z-index: 0;
    position: absolute;
    margin-right: 278px;
    left: 34px;
    top: -49px;
  };
  
  .card_position_left {
      z-index: 0;
      position: absolute;
      left: 365px;
      top: -133px;
  };
  .img_right{
    margin-right: 113px;
  };
  .image_wrapper_7{
    width: 82px;
    height: 50px;
  }
};

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); // Slight upward motion
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.typing-indicator {
  display: inline-flex;
  gap: 0.2rem;

  span {
    width: 4px;
    height: 4px;
    background-color: gray;
    border-radius: 50%;
    animation: typing-bounce 1s infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes typing-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.chat-box {
  position: relative;
  height: 70vh;
  display: flex;
  flex-direction: column;

  .messages-area {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 60px; // Leave space for the input area
  }

  .input-area {
    // background-color: #f9f9f9;
    padding: 10px;
    // box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);

    .typing-indicator {
      display: flex;
      gap: 5px;

      span {
        width: 8px;
        height: 8px;
        background-color: gray;
        border-radius: 50%;
        animation: typing 1.5s infinite ease-in-out;

        &:nth-child(1) {
          animation-delay: 0s;
        }

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes typing {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}

.placeholder-style::placeholder {
  color: #a8a8a8; // Light gray or any color you prefer
  font-size: 0.875rem; // Adjust the size as needed (e.g., smaller than 1rem)
  opacity: 1; // Ensures full visibility of the placeholder
}
