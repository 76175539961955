#login_container {
  label {
    font-weight: 400;
    color: #1c334a;
  }
  .form-control {
    border-radius: 0px;
    border: 1px solid #efefef;
    padding: 3px 5px;
    font-size: 14px;
    height: 32px;
  }
  .form-control:focus {
    background-color: #fff;
    outline: 0;
    box-shadow: none;
    border: 1px solid #efefef;
    border-bottom: 1px solid #007bff;
  }
}


// button
.al_button_16 {
  background: white !important;
  border: 1px solid #5bb5a2 !important;
  color: #5bb5a2 !important;
  border-radius: 5px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.al_button_16:hover,
.al_button_16:visited,
.al_button_16:focus {
  background: #5bb5a2 !important;
  border: 1px solid #5bb5a2 !important;
  color: #fff !important;
  // font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.al_button_16.active {
  background: #5bb5a2 !important;
  border: 2px solid #fff !important;
  color: #fff !important;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

// ------------------new Login css----------------------------------------
.fw_1 {
  font-weight: 600 !important;
}
.fw_2 {
  font-weight: 400;
  font-size: 16px;
}
.br_radius_1 {
  border-radius: 1.3em !important;
}
.card-radius {
  border-radius: 0.5em !important;
}
.card-radius_1 {
  border-radius: 0.7em !important;
}
.mt_1 {
  margin-top: 160px !important;
}

.text_color {
  color: #5bb5a2;
}
.text_color_1{
  color: #FFFFFF !important;
}
.bg_color_1 {
  background: #e8f2ee;
}
.bg_color_2 {
  background: #5bb5a2;
  border-radius: 8px;
}
.bg_color_3 {
  background: rgba(255, 255, 255, 0.2);
}
.bg_color_4 {
  padding: 0px;
  gap: 10px;
  background: #e8f2ee;
}
.eye-icon {
  position: absolute;
  top: 13px;
  right: 30px;
  cursor: pointer;
  color: gray;
}
.blog_h {
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 120%;
  letter-spacing: -0.03em;
}
.input_box {
  box-sizing: border-box;
  padding: 0px 16px 0px 14px !important;
  background: #ffffff;
  border: 1px solid #d0d0d0 !important;
  border-radius: 4px !important;
  letter-spacing: 0.0125em;
  height: 40px !important;
};
.input_number_1 {
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0;
  text-align: center;
  border: none;
  font-size: 16px;
  width: 60px;
  height: 26px;
  outline: none
};

.pl_1 {
  padding-left: 9rem !important;
}

.btn_1 {
  background: #5bb5a2 !important;
  border-radius: 0.3rem;
  color: #ffffff;
}
.btn_2 {
  color: #5bb5a2 !important;
  border-radius: 0.3rem;
  border: 1px solid #5bb5a2 !important;
  background: white;
}
.btn_icon_2 {
  // position: absolute;
  color: #5bb5a2;
  border-radius: 2rem;
  background: rgba(91, 181, 162, 0.25);
};
.btn_icon_3{
  background: rgba(255, 255, 255, 0.2);
  color: #fff !important;
  border-radius: 2rem;
}
.f_l {
  float: left !important;
}
.f_r {
  float: right !important;
}

.par_20 {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #363940;
}
.par_29 {
  font-family: inherit;
  font-weight: 400;
  font-size: 2.9rem;
  letter-spacing: -0.03em;
}
.par_30 {
  font-weight: 500;
  font-size: 50px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #1a191e;
}
// .par_31{
// font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 14px;
// line-height: 25px;
// // letter-spacing: 0.02em;
// color: #000000;
// }
.par_32{
  font-size: 80%;
  font-weight: 400;
}
.par_33{
  font-size: 1.3rem;
  color: rgb(91, 181, 162);
  font-weight: 500;
}

/* Media Query for Mobile Devices */
@media (min-width: 320px) and (max-width: 620px) {
  .text-align {
    text-align: center !important;
  }
  .text-align_left_mob {
    text-align: left !important;
  }
  .text-align_1 {
    display: none;
  }
  .text-align_2 {
    display: block;
    color: #5bb5a2;
    text-align: center;
    font-size: 1.2em;
  }
  .text-align_3 {
    display: none;
  }
  .img_text_hide_mobile_view {
    display: none;
  }
  .blog_sub_h {
    // font-family: 'DM Sans';
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
  }
  .pl_1 {
    padding-left: 3rem !important;
  }
  .ff_mob_view {
    font-weight: 500;
    font-size: 24px;
    line-height: 180%;
  }
  .mt_2 {
    margin-top: 10px !important;
  }
  .mt_90 {
    margin-top: 10px !important;
  }
  // mobile viwe all font css-------------

  .par_20 {
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #363940;
  }
  .mob_par_21 {
    font-weight: 500;
    font-size: 38px;
    line-height: 50px;
    // letter-spacing: 2px;
    color: #1a191e;
  }
  .mob_par_22 {
    // font-family: "Cabin";
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    color: #343434;
  }
  .mob_par_23 {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #999999;
  }
  .mob_par_24 {
    font-weight: 400;
    font-size: 18px;
    //line-height: 22px;
    color: #000000;
  }
  .mob_par_25 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #737373;
  }
  .mob_par_26 {
    font-size: 1.428rem;
    font-weight: 600;
    color: var(--color-15) !important;
  }
  .mob_par_27 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #1a191e;
  }
  .mob_par_28 {
    font-size: 1.428rem;
    font-weight: 250;
    color: var(--color-15) !important;
  }
  .mob_par_30 {
    font-weight: 500;
    font-size: 24px;
    line-height: 180%;
    color: #ffffff;
  }
}

/* Media Query for Large screens */
@media (min-width: 621px) {
  .text-align_1 {
    display: block;
    color: #5bb5a2;
    text-align: center;
    font-size: 1.2em;
  }
  .text-align_2 {
    display: none;
  }
  .w_1 {
    width: 55% !important;
  }
  .blog_sub_h {
    display: none;
  }
  .mob_par_28 {
    font-size: 0.9rem !important;
  }
}
//--------new ui css------------
.input-container {
	padding-bottom: 1em;
}
.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 35px !important; 
}
.left-inner-addon i {
    position: absolute;
    padding: 12px 12px;
    pointer-events: none;
}
//pass

.left-and-right-inner-addon {
  position: relative;
}
.left-and-right-inner-addon input {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.left-and-right-inner-addon i.left {
  position: absolute;
  padding: 12px 12px;
  pointer-events: none;
}
.left-and-right-inner-addon i.right {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  pointer-events: none;
}